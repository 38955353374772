<template>
	<div class="questions">
		<textarea name="" id="" v-model="textValue" cols="30" rows="10"></textarea>
		<br>
		<button @click="submitQuestion()">enviar</button>
		<p v-if="submitted">pregunta enviada: {{ submittedQuestion }}</p>
		<p v-if="error">error</p>
	</div>
</template>
  
<script>
import router from '@/router'
import { useAppStore } from '@/stores/index.js'

export default {
	name: 'SubmitQuestionView',
	data() {
		return {
			textValue: '',
			submittedQuestion: null,
			submitted: false,
			error: false,
		}
	},

	methods: {
		submitQuestion() {
			if (this.textValue.length > 0) {
				this.submitted = true
				this.error = false

				useAppStore().addSubmittedQuestion({
					title: this.textValue,
					id: this.textValue.length
				})
				this.$router.push('/preguntas')
			}
		}
	}
}
</script>
  