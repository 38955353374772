<template>
  <div class="view home">
		<h1>Hola, {{ name }}!</h1>
		<h2>información</h2>
		<router-link to="/preguntas">preguntas y respuestas</router-link>
		<router-link to="/testycontroles">test y controles</router-link>
		<router-link to="/podcasts">podcasts</router-link>
		<router-link to="/testimonios">testimonios</router-link>
		<!-- <p>
			<router-link to="/articulos">ver todos los contenidos</router-link>
		</p> -->
  </div>
</template>

<script>
import { useAppStore } from '@/stores/index.js'
export default {
  name: 'HomeView',
	data() {
		return {
			name: useAppStore().accountData.name
		}
	}
}
</script>

<style>
.home {
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 3em;
}
</style>
