<template>
	<div class="form pin-group">
		<div ref="pinGroup" class="flex-center gap8">
			<PinInput
				v-for="index in pinLength"
				:key="index"
				:id="'pin-' + index"
				:model-value="pinInputs['digit' + index]"
				:length="pinLength"
				@update:model-value="newValue => pinInputs['digit' + index] = newValue"
				@next="focusNext(index)"
				@prev="focusPrev(index)"
				@pasteText="pasteText"
			/>
		</div>
		<button
			@click="toggleVisibility"
			class="btn-text"
		>
			<svg v-if="!pinVisible" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M1.33325 7.99998C1.33325 7.99998 3.33325 3.33331 7.99992 3.33331C12.6666 3.33331 14.6666 7.99998 14.6666 7.99998C14.6666 7.99998 12.6666 12.6666 7.99992 12.6666C3.33325 12.6666 1.33325 7.99998 1.33325 7.99998Z" stroke="#474747" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
				<path d="M8 10C9.10457 10 10 9.10457 10 8C10 6.89543 9.10457 6 8 6C6.89543 6 6 6.89543 6 8C6 9.10457 6.89543 10 8 10Z" stroke="#474747" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
			</svg>
			<svg v-else width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
				<g clip-path="url(#clip0_87_1397)">
				<path d="M6.58675 6.58667C6.39025 6.76977 6.23265 6.99057 6.12334 7.2359C6.01402 7.48123 5.95524 7.74607 5.95051 8.01461C5.94577 8.28315 5.99517 8.54989 6.09576 8.79893C6.19635 9.04797 6.34607 9.27419 6.53598 9.46411C6.7259 9.65402 6.95212 9.80374 7.20116 9.90433C7.45019 10.0049 7.71694 10.0543 7.98548 10.0496C8.25402 10.0448 8.51885 9.98606 8.76419 9.87675C9.00952 9.76744 9.23032 9.60983 9.41342 9.41334" stroke="#474747" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
				<path d="M7.15332 3.38665C7.43419 3.35165 7.71694 3.33384 7.99999 3.33331C12.6667 3.33331 14.6667 7.99998 14.6667 7.99998C14.3686 8.63806 13.9948 9.23793 13.5533 9.78665" stroke="#474747" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
				<path d="M4.40659 4.40668C3.08075 5.30976 2.01983 6.55018 1.33325 8.00001C1.33325 8.00001 3.33325 12.6667 7.99992 12.6667C9.27719 12.6701 10.5271 12.2967 11.5933 11.5933" stroke="#474747" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
				<path d="M1.33325 1.33331L14.6666 14.6666" stroke="#474747" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
				</g>
				<defs>
				<clipPath id="clip0_87_1397">
				<rect width="16" height="16" fill="white"/>
				</clipPath>
				</defs>
			</svg>
			<span v-if="!pinVisible">Ver clave</span>
			<span v-else>Ocultar clave</span>
		</button>
		<p class="hint" v-if="showHint">Evitá usar números consecutivos, fechas importantes o datos personales.</p>
	</div>
</template>
<script>
import PinInput from './PinInput.vue'
export default {
	name: 'PinInputGroup',
	components: {PinInput},
	props: ['pinLength', 'showHint'],
	emits: ['valid'],

	data() {
		return {
			pinInputs: {},
			pinVisible: false
		}
	},

	computed: {
		pinCode() {
			if (this.pinInputs) {
				let code = '';
				for (const [key, value] of Object.entries(this.pinInputs)) {
					code += value
				}
				return code
			}

			else return undefined
		},

		isValid() {
			return this.pinCode.length == this.pinLength
		}
	},

	watch: {
		isValid(newValue, oldValue) {
			if (newValue === true) {
				this.$emit('valid', this.pinCode)
			}

			else {
				this.$emit('invalid')
			}
		}
	},

	methods: {
		focusNext(index) {
			index = index - 1
			if (this.$refs.pinGroup?.children[index + 1]) {
				this.$refs.pinGroup.children[index + 1]?.focus();
			}
		},

		focusPrev(index) {
			index = index - 1
			if (index > 0) {
				this.$refs.pinGroup.children[index - 1]?.focus();
			}
		},

		pasteText(text) {
			for (let i = 0; i <= text.length; i++) {
				this.pinInputs['digit' + (i + 1)] = text[i]
			}
		},

		toggleVisibility() {
			document.querySelectorAll('.code-glyph').forEach(function(input) {
				if (input.getAttribute('type') == 'password') {
					input.setAttribute('type', 'text')
				}

				else if (input.getAttribute('type') == 'text') {
					input.setAttribute('type', 'password')
				}
			})

			this.pinVisible = !this.pinVisible
		}
	},

	mounted() {
		for (let i = 0; i <= this.pinLength - 1; i++) {
			this.pinInputs['digit' + (i + 1)] = ''
		}
	}
}
</script>

<style>
.pin-group {
	display: flex;
	flex-direction: column;
	gap: 10px;
}

.btn-text {
	border: 0;
	display: flex;
	margin: auto;
	gap: 8px;
	align-items: center;
}

.hint {
	font-size: var(--type-bxs-size);
	font-weight: var(--type-bxs-weight);
	text-align: center;
}
</style>