import UserEvent from './UserEvent.js';
import { useAppStore } from '@/stores/index.js'

const userEventLogger = {
	logArticleLeave(articleId, read, datetime) {
		const userEvent = {
			'entity': 'Articulo',
			'entity_id': articleId,
			'activity_type': 'App',
			'activity': 'ARTICLE_LEAVE',
			'user': useAppStore().accountData.userId,
			'value': JSON.stringify({'read': read, 'dateTime': datetime})
		}

		this.logEvent(userEvent)
	},

  logPageVisit(pageUrl) {
    const event = new UserEvent('page_visit', { pageUrl });
    // this.logEvent(event);
  },

	logPageLeave(pageUrl) {
		const event = new UserEvent('page_leave', { pageUrl });
    // this.logEvent(event);
	},

	logVideoAction(event, articleId) {
		const userEvent = {
			'entity': 'Video',
			'entity_id': articleId,
			'activity_type': 'App',
			'activity': this.getVideoActionType(event),
			'user': useAppStore().accountData.userId,
			'value': this.secondsToTimestamp(event.target.playerInfo.currentTime)
		}
		
		this.logEvent(userEvent)
	},

	getVideoActionType(event) {
		if (event.data == 0) { return 'VIDEO_END' }
		else if (event.data == 1) { return 'VIDEO_START' }
		else if (event.data == 2) { return 'VIDEO_PAUSE' }
		else return event.data
	},

  // logElementClick(elementId, elementType) {
  //   const event = new UserEvent('element_click', { elementId, elementType });
  //   this.logEvent(event);
  // },

  logEvent(userEvent) {
		useAppStore().addUserEvent(userEvent)
  },

	secondsToTimestamp(seconds) {
    if (seconds > 0) {
			seconds = Math.floor(seconds)
			const formattedMinutes = String(Math.floor(seconds / 60)).padStart(2, '0')
			const formattedSeconds = String(seconds % 60).padStart(2, '0')
			return `${formattedMinutes}:${formattedSeconds}`
		}
	}
};

export default userEventLogger;