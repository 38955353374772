<template>
	<div class="view article" v-if="article" ref="article">
		<h1>{{ article.title }}</h1>
		<p>{{ article.subtitle }}</p>
		<div
			class="article-content"
			v-html="article.content"
		></div>
		<div
			v-if="article.articleTypeCode == 'STO' && article.url"
			id="videoPlayer"
		></div>
		<div
			v-if="article.articleTypeCode == 'POD' && article.url"
		>
			<audio :src="url"></audio>
		</div>
	</div>
</template>

<script>
import { useAppStore } from '@/stores/index.js'
import userEventLogger from '@/eventLogger.js';
export default {
	name: 'ArticleView',
	data() {
		return {
			article: null,
			videoEventsLogged: false
		}
	},

	computed: {

	},

	methods: {
		onPlayerStateChange(event) {
			if ([0, 1, 2].includes(event.data)) {
				userEventLogger.logVideoAction(event, this.article.articleId)
			}
		},
	},

	created() {
		fetch(process.env.VUE_APP_URL_API + `api/article/read`, {
			method: 'POST',
			headers: new Headers({
				'Authorization': 'Basic '+btoa('tita_user:ef4515ee-5e13-423d-89f1-a2f4730ad346'), 
				"Content-Type": "application/json",
			}),
			body: JSON.stringify({
				articleId: this.$route.params.id,
				user: useAppStore().accountData.userId,
				token: useAppStore().accountData.token,
				ip: "74.207.237.175",
				online: "true",
				source: "APP"
			})
		})
			.then(response => response.json())
			.then(data => this.article = data)
			.catch(error => console.error('Error:', error));
	},

	updated() {
		if (this.article.articleTypeCode == 'STO' && this.article.url) {
			const tag = document.createElement('script');
			tag.src = 'https://www.youtube.com/iframe_api';
			const firstScriptTag = document.getElementsByTagName('script')[0];
			firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

			window.onYouTubeIframeAPIReady = () => {
				this.player = new window.YT.Player('videoPlayer', {
					height: '360',
					width: '640',
					videoId: this.article.url,
					events: {
						'onReady': this.onPlayerReady,
						'onStateChange': this.onPlayerStateChange
					}
				});
			};
		}
	},
	beforeRouteLeave(to, from) {
		if (this.article.articleTypeCode == 'TES' || this.article.articleTypeCode == 'PRE') {
			var article = this.$refs.article
			var scrolledToBottom = article.scrollHeight - Math.round(article.scrollTop) <= article.clientHeight
			userEventLogger.logArticleLeave(this.article.articleId, scrolledToBottom, new Date().toISOString())
		}

		if (useAppStore().userEvents.length > 0) {
			useAppStore().submitUserEvents()
		}
  },
}
</script>

<style scoped>
.view.article {
	overflow-y: auto;
}
</style>