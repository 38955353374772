<template>
	<div class="view ac-step">
		<LoadingSpinner
			v-if="emailSubmitted && !emailSent && !emailVerified && !error"
		/>
		<div class="email-form flex-v" v-if="!emailSubmitted && !emailSent && !error">
			<h2>¿Cuál es tu email?</h2>
			<p>Una vez ingresado tendrás que validarlo.</p>
			<div class="form">
				<div class="form-field-vertical">
					<label>Tu email</label>
					<input type="email" placeholder="usuaria@gmail.com" v-model="email" @keyup.enter="focusNextInput()">
				</div>
				<div class="form-field-vertical">
					<label>Repetí tu email</label>
					<input type="email" ref="confirm-email" placeholder="usuaria@gmail.com" v-model="repeatEmail" @keyup.enter="isValid ? verifyEmail() : false">
				</div>
			</div>
			<div class="btn-container flex-v">
				<button
					class="btn btn-primary btn-lg"
					:disabled="!isValid"
					@click="verifyEmail"
				>
					Siguiente
				</button>
			</div>
		</div>
		<div class="confirm flex-v" v-if="emailSent && !emailVerified & !error">
			<div class="msg">
				<div class="img-container">
					<img :src="require(`@/assets/img/mensaje.gif`)" alt="">
				</div>
				<div class="msg-text">
					<h2>¿Recibiste el email?</h2>
					<p>Te enviamos un correo a {{ email }} para {{ reregister ? 'validar tu identidad' : 'confirmar tu dirección' }}.</p>
				</div>
				<p>Si no lo recibiste, mirá en spam.</p>
			</div>
			<div class="btn-container flex-v">
				<button 
					class="btn btn-secondary btn-lg"
					type="button"
					:disabled="countdown > 0"
				>
					Reenviar mail{{ countdown > 0 ? ` en 00:${paddedCountdown}` : ''}}
				</button>
			</div>
		</div>
		<div class="success flex-v" v-if="emailVerified && !error">
			<div class="msg">
				<div class="img-container">
					<img :src="require(`@/assets/img/success.svg`)" alt="">
				</div>
				<h2>¡Confirmaste tu email!</h2>
				<p>Esto es importante para recuperar tu contraseña si lo necesitás.</p>
			</div>
			<div class="btn-container">
				<button class="btn btn-primary btn-lg" type="button" @click="nextStep()">Siguiente</button>
			</div>
		</div>
		<div class="error flex-v" v-if="error">
			<div class="msg">
				<div class="img-container">
					<img :src="require(`@/assets/img/error.svg`)" alt="">
				</div>
				<h2>Error</h2>
				<p></p>
			</div>
			<div class="btn-container">
				<button class="btn btn-primary btn-lg" type="button" @click="error = false"></button>
			</div>
		</div>
		<div class="timeout-error flex-v" v-if="timeout">
			<div class="msg">
				<div class="img-container">
					<img :src="require(`@/assets/img/error.svg`)" alt="">
				</div>
				<h2>Tu tiempo expiró</h2>
				<p>Reenviá el mail para intentarlo nuevamente.</p>
			</div>
			<p>Si no lo recibiste, mirá en spam.</p>
			<div class="btn-container">
				<button class="btn btn-primary btn-lg" type="button" @click="verifyEmail">Reenviar email</button>
			</div>
		</div>
	</div>
</template>

<script setup>
import { useAppStore } from '@/stores/index.js'
</script>
<script>
import LoadingSpinner from '@/components/LoadingSpinner.vue'

export default {
	name: 'ACEmail',
	data() {
		return {
			email: undefined,
			repeatEmail: undefined,
			emailSubmitted: false,
			emailSent: false,
			emailVerified: false,
			emailRegex: /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+)$/,
			countdown: 45,
			error: false,
			timeout: false,
			reregister: false
		}
	},

	components: {LoadingSpinner},

	computed: {
		isValid() {
			return this.email === this.repeatEmail
			&& this.email?.length > 1 
			&& this.email?.match(this.emailRegex)?.length > 0
		},

		paddedCountdown() {
			return this.countdown < 10 ? '0' + this.countdown : this.countdown
		}
	},

	methods: {
		focusNextInput() {
			this.$refs.input.focus()
		},
		
		nextStep() {
			this.$router.push({name: 'PIN'})
			this.$emit('step-change', 5)
		},

		onVerifyEmail(response) {
			if (response.code == 'VALID_EMAIL' || response.code == 'INVALID_EMAIL' && this.reregister) {
				this.emailSent = true
				this.startTimer()
			}

			else if (response.code == 'EXPIRED_EMAIL') {
				this.timeout = true
			}

			else if (response.code == 'INVALID_EMAIL' && !this.reregister) {
				this.error = true
			}
		},

		onVerifyThroughLink() {
			this.$emit('submit', 'email', this.$route.query.email)
			this.$emit('submit', 'hasValidEmail', true)
			this.emailSent = true
			this.emailVerified = true
		},

		verifyEmail() {
			this.emailSubmitted = true
			this.error = false
			this.timeout = false
			fetch(process.env.VUE_APP_URL_API + 'api/user/isValidEmail', {
				method: 'POST',
				headers: new Headers({
					'Authorization': 'Basic '+btoa('tita_user:ef4515ee-5e13-423d-89f1-a2f4730ad346'), 
					"Content-Type": "application/json",
				}), 
				body: JSON.stringify({
					email: this.email,
					cuid: useAppStore().accountData.cuid
				})
			})
			.then(response => response.json())
			.then(response => this.onVerifyEmail(response))
			.catch(error => console.error('Error:' + error))
		},

		startTimer() {
			const timer = setInterval(() => {
        if (this.countdown > 0) {
          this.countdown--;
        } else {
          // this.isDisabled = false;
          clearInterval(timer);
        }
      }, 1000);
		}
	},

	mounted() {
		this.$emit('step-change', 4)
		if (useAppStore().accountData.email?.length > 0) {
			this.email = useAppStore().accountData.email
		}

		if (this.$route.query?.email?.length && this.$route.query?.valid == 'true') {
			this.onVerifyThroughLink()
		}

		else if (this.$route.query?.reregister) {
			this.reregister = true
			this.verifyEmail()
		}
	}
}
</script>

<style>
/* patch para que, estando el botón de siguiente fijo en el borde inferior, no quede inaccesible el segundo input */
.email-form {
	height: calc(100dvh - env(keyboard-inset-height, 0) - 144px);
	overflow: auto;
}
</style>