<template>
	<div
		v-if="submittedQuestions.length > 0"
		class="view articles flex-v gap24"
	>
		<p>preguntas enviadas:</p>
		<ul>
			<li
				v-for="question in submittedQuestions"
				class="article-card"
				:key="question.id"
			>
				{{ question.title }}
			</li>
		</ul>
	</div>
	<div v-else>
		<p>no hay preguntas enviadas</p>
	</div>
</template>

<script>
import { useAppStore } from '@/stores/index.js'

export default {
	name: 'SubmittedQuestionsView',
	data() {
		return {
			submittedQuestions: []
		}
	},

	mounted() {
		this.submittedQuestions = useAppStore().submittedQuestions
	},
}
</script>

<style scoped>
.articles {
	padding: 3em;
}
</style>