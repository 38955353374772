<template>
  <div class="view ac-step ac-cuid">
		<LoadingSpinner
			v-if="cuid && valid == undefined"
		/>
		<div class="cuid-form display-contents" v-if="!cuid">
			<h2>Ingresá el código que te asignó tu asistente de salud</h2>
			<div class="form">
				<input type="text" v-model="input" placeholder="" @keyup.enter="submitCuid"/>
			</div>
			<div class="btn-container">
				<button
					type="button"
					class="btn btn-primary btn-lg"
					@click="submitCuid()"
					>
						{{ msg.common.verify }}
				</button>
			</div>
		</div>
		<div class="success flex-v" v-if="cuid && valid">
			<div class="msg">
				<div class="img-container">
					<img :src="require(`@/assets/img/success.svg`)" alt="">
				</div>
				<h2>Código correcto</h2>
				<p>Verificaste tu código. Ahora podemos comenzar.</p>
			</div>
			<div class="btn-container">
				<button class="btn btn-primary btn-lg" type="button" @click="nextStep()" :disabled="input.length == 0">Siguiente</button>
			</div>
		</div>
		<div class="error flex-v" v-if="cuid && valid == false">
			<div class="msg">
				<div class="img-container">
					<img :src="require(`@/assets/img/error.svg`)" alt="">
				</div>
				<h2>Código incorrecto</h2>
				<p>Por favor, al ingresar el código verificá que todos los caracteres sean correctos e inténtalo nuevamente.</p>
			</div>
			<div class="btn-container">
				<button class="btn btn-primary btn-lg" type="button" @click="tryAgain()">Ingresar código</button>
			</div>
		</div>
  </div>
</template>

<script setup>
import { useAppStore } from '@/stores/index.js'
</script>
<script>
import messages from '@/lang/es.js'
import LoadingSpinner from '@/components/LoadingSpinner.vue'
export default {
	name: 'ACCuid',
  data() {
    return {
			input: '',
			cuid: undefined,
			valid: undefined,
			existent: undefined,
    }
  },

	components: {LoadingSpinner},

	methods: {
		submitCuid() {
			this.cuid = this.input
			this.validateCuid()
		},

		tryAgain() {
			this.cuid = undefined
			this.valid = undefined
			this.input = ''
		},

		validateCuid() {
			fetch(process.env.VUE_APP_URL_API + 'api/user/isValidCuid', {
				method: 'POST',
				headers: new Headers({
					'Authorization': 'Basic '+btoa('tita_user:ef4515ee-5e13-423d-89f1-a2f4730ad346'), 
					"Content-Type": "application/json",
				}), 
				body: JSON.stringify({cuid: this.cuid})
			})
				.then(response => response.json())
				.then(response => this.onValidateCuidResponse(response))
				.catch(error => console.error('Error: ' + error))
		},

    nextStep() {
			if (this.valid && !this.existent) {
				this.$emit('submit', 'cuid', this.cuid)
				this.$router.push({name: 'onboarding'})
			}
    },

		onValidateCuidResponse(response) {
			if (response.resultDto.code == 'VALID_CUID') {
				this.valid = true
				this.existent = false
			}

			else if (response.resultDto.code == 'EXIST_CUID') {
				this.valid = true
				this.existent = true
				useAppStore().setAccountProperty('cuid', response.userDto.cuid)
				useAppStore().setAccountProperty('email', response.userDto.email)
				this.$router.push({name: 'Email', query: {'reregister': true}})
			}

			else if (response.resultDto.code == 'INVALID_CUID') {
				this.valid = false
				this.existent = false
			}
		}
  },

	created() {
		this.msg = messages
	},

  mounted() {
		if (this.$route.query.cuid) {
			this.cuid = this.$route.query.cuid
			this.validateCuid()
		}
  },
};
</script>