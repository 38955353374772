class UserEvent {
  constructor(entity, details) {
    this.entity = entity;
    this.timestamp = new Date();
		// this.cuid = 0;
		// this.ip = 0;
		// this.phone = ''
    this.details = details;
  }
}

// type: video | calendar | pageview / pageleave | ???
// details:
// video: 
//	action:	start | pause | end | leave | skip?
//  id: videoid
//  videoTimestamp: hh:mm:ss

export default UserEvent;