<template>
	<div class="walkthrough">
		<div class="slides">
			<WTSlide
				:slide="currentSlide"
				@termsAccepted="updateTerms"
			/>
		</div>
		<div
			v-show="this.wt.currentStep > 0 && this.wt.currentStep < this.wt.slides.length - 1"
			class="progress-dots flex-center gap8"
		>
			<div
				v-for="(slide) in this.wt.slides"
				:key="slide.order"
				class="progress-dot"
				:class="{ 
					'active': this.wt.currentStep === slide.order - 1,
					'hide': slide.order === 1 || slide.order === 5
				}"
				></div>
		</div>
		<div class="btn-container flex-center">
			<button
				class="btn btn-primary btn-lg"
				@click="nextStep"
				:disabled="this.wt.currentStep == 4 && !this.wt.termsAccepted"
			>Siguiente</button>
		</div>
	</div>
</template>

<script>
import messages from '@/lang/es.js'
import WTSlide from '@/components/WTSlide.vue'

export default {
	name: 'onboardingView',
	data() {
		return {
			msg: {},
			wt: {
				currentStep: 0,
				termsAccepted: false,
			}
		}
	},

	computed: {
		currentSlide() {
			return this.wt.slides[this.wt.currentStep]
		}
	},

	components: {
		WTSlide
	},

	methods: {
		nextStep() {
			if (this.wt.currentStep < this.wt.slides.length - 1) {
				this.wt.currentStep += 1
			}

			else if (this.wt.currentStep == this.wt.slides.length - 1) {
				this.$router.push({name: 'Inicio'})
			}
		},

		updateTerms(data) {
			this.wt.termsAccepted = data
		}
	},

	created() {
		this.msg = messages

		this.wt.slides = [
			{
				'order': 1,
				'image': require(`@/assets/img/logo.svg`),
				'title': messages.walkthrough.wt1Title,
				'content': messages.walkthrough.wt1Content,
			},
			{
				'order': 2,
				'image': require(`@/assets/img/idea.gif`),
				'title': messages.walkthrough.wt2Title,
				'content': messages.walkthrough.wt2Content,
			},
			{
				'order': 3,
				'image': require(`@/assets/img/calendario.gif`),
				'title': messages.walkthrough.wt3Title,
				'content': messages.walkthrough.wt3Content,
			},
			{
				'order': 4,
				'image': require(`@/assets/img/mensaje.gif`),
				'title': messages.walkthrough.wt4Title,
				'content': messages.walkthrough.wt4Content,
			},
			{
				'order': 5,
				'image': '',
				'title': 'Términos y condiciones',
				'content': messages.terms
			}
		]
	},

	mounted() {
	}
}
</script>

<style scoped>
.hide {
	display: none;
}

.walkthrough {
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 24px 24px 32px;
}

.slides {
	height: 100%;
}

.progress-dots {
	margin-bottom: 86px;
}

.progress-dot {
	width: 16px;
	height: 16px;
	background-color: var(--color-g20);
	border-radius: 50%;
	&.active {
		background-color: var(--color-g40);	
	}
}
</style>