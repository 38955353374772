<template>
	<div id="account-creation" class="walkthrough" v-if="!accountCreated">
		<TopMenu
			v-if="currentStep > 1 && !loading"
			:currentStep="currentStep"
			:totalSteps="totalSteps"
			:showNavBack="true"
			:showProgBar="true"
		/>
		<LoadingSpinner
			v-if="loading"
		/>
		<router-view
			v-if="!loading"
			@step-change="updateStep"
			@submit="updateData"
		/>
	</div>
	<div class="view ac-step success" v-else>
		<div class="msg">
			<div class="img-container">
				<img :src="require(`@/assets/img/success.svg`)" alt="">
			</div>
			<div class="msg-text">
				<h1>¡Comenzá a usar la app!</h1>
				<p>Completaste el registro de Tita.</p>
			</div>
		</div>
		<div class="btn-container">
			<button class="btn btn-primary btn-lg" type="button" @click="login()">Comencemos</button>
		</div>
	</div>
</template>

<script>
import TopMenu from '@/components/TopMenu.vue'
import LoadingSpinner from '@/components/LoadingSpinner.vue'
import { useAppStore } from '@/stores/index.js'

export default {
	data() {
		return {
			currentStep: 0,
			totalSteps: 5,
			accountCreated: false,
			loading: false
		}
	},

	components: {
		TopMenu,
		LoadingSpinner
	},
	
  methods: {
		createAccount() {
			this.loading = true
			fetch(process.env.VUE_APP_URL_API + `api/user/new`, {
				method: 'POST',
				headers: new Headers({
					'Authorization': 'Basic '+btoa('tita_user:ef4515ee-5e13-423d-89f1-a2f4730ad346'), 
					"Content-Type": "application/json",
				}), 
				body: JSON.stringify(useAppStore().accountData)
			})
				.then(response => response.json())
				.then(response => this.onAccountCreated(response))
				.catch(error => console.error('Error:', error));
		},

		login() {
			this.$router.push({name: 'Login'})
		},

		onAccountCreated(data) {
			this.accountCreated = true
			this.loading = false
			useAppStore().clearAccountData({})
			useAppStore().setAccountProperty('token', data.token)
			useAppStore().setAccountProperty('name', data.name)
			useAppStore().setAccountProperty('email', data.email)
		},

		updateData(key, value) {
			useAppStore().accountData[key] = value

			if (this.currentStep == this.totalSteps) {
				this.createAccount()
			}
		},

    updateStep(step) {
      this.currentStep = step;
    }
  },

	mounted() {
	}
}
</script>

<style>
	#account-creation,
	.email-form,
	.pin-form,
	.confirm,
	.success,
	.error {
		height: 100%;
	}

	#account-creation {
		display: flex;
		flex-direction: column;
	}

	.ac-step {
		flex-grow: 1;
		display: flex;
    flex-direction: column;
		padding: 24px;
	}

	.top-menu:has(+.ac-step > .confirm, 
		+.ac-step .success,
		+.ac-step .loader) {
		visibility: hidden;
	}

	.ac-step .form {
		display: flex;
		flex-direction: column;
		gap: 32px;
		margin-top: 32px;
	}

	.form input {
		width: 100%;
	}

	.form-field-vertical label {
		color: var(--color-n80);
	}

	.msg {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
		padding-top: 24px;
	}

	.msg .img-container {
		margin-bottom: 40px;
	}

	.msg img {
		max-width: 9em;
	}
</style>