<template>
	<div class="progress-bar-container">
		<div class="progress-bar" :style="{ width: progressWidth }"></div>
	</div>
</template>

<script>
export default {
	name: 'ProgressBar',
	props: ['currentStep', 'totalSteps'],
	computed: {
    progressWidth() {
			if (this.currentStep < 3) {
				return 0
			}

      else return ((this.currentStep - 1) / this.totalSteps) * 100 + '%';
    }
  }
}
</script>