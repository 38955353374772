<template>
	<div class="view ac-step">
		<div class="pin-form flex-v" v-if="!done">
			<h2>¡Último paso! Creá una clave de seguridad</h2>
			<p>Utilizarás esta clave para desbloquear la app cada vez que ingreses.</p>
			<PinInputGroup
				:pinLength="pinLength"
				@valid="onValidPinEntered"
				@invalid="onInvalidPinEntered"
			/>
			<div class="btn-container flex-v">
				<button
					class="btn btn-primary btn-lg"
					@click="submitPin"
					:disabled="!pinValid"
				>
					Siguiente
				</button>
			</div>
		</div>
	</div>
</template>

<script>
import PinInputGroup from '../PinInputGroup.vue';
export default {
	name: 'ACPin',
	emits: ['submit'],
	
	data() {
		return {
			done: false,
			pinLength: 4,
			pin: undefined,
			pinValid: false
		}
	},

	components: {PinInputGroup},

	methods: {
		onInvalidPinEntered() {
			this.pinValid = false
			this.pin = undefined
		},

		onValidPinEntered(input) {
			this.pinValid = true
			this.pin = input
		},

		submitPin() {
			this.$emit('submit', 'password', this.pin)
			this.done = true
		},
	},

	mounted() {
		this.$emit('step-change', 5);
	},
}
</script>