<template>
  <div class="view ac-step">
		<h2>Estás por comenzar a crear tu cuenta</h2>
		<p>En los próximos pasos, te pediremos que completes algunos datos.</p>
		<div class="btn-container">
			<button type="button" class="btn btn-primary btn-lg" @click="nextStep()">{{ messages.common.next }}</button>
		</div>
  </div>
</template>

<script>
export default {
	name: 'ACStart',

  mounted() {
    this.$emit('step-change', 1);
  },

  methods: {
    nextStep() {
      this.$router.push({name: 'Nombre'});
      this.$emit('step-change', 2);
    }
  }
};
</script>