<template>
  <div class="view ac-step">
		<h2>¿Cuál es tu nombre?</h2>
		<p>Elegí el nombre o apodo que te gusta usar. Lo usaremos para comunicarnos, asegurate que no tenga información identificable (como tu apellido)</p>
		<div class="form">
			<input type="text" v-model="displayName" placeholder="Usuaria" @keyup.enter="nextStep" />
		</div>
		<div class="btn-container">
			<button
				type="button"
				class="btn btn-primary btn-lg"
				:disabled="!isValid"
				@click="nextStep"
				>
					{{ msg.common.next }}
			</button>
		</div>
  </div>
</template>

<script>
import messages from '@/lang/es.js'
import { useAppStore } from '@/stores';
export default {
	name: 'ACName',
  data() {
    return {
			displayName: '',
    }
  },

	computed: {
		isValid() {
			return this.displayName.length > 0 && this.displayName.length < 24
		}
	},

	methods: {
    nextStep() {
      this.$router.push({name: 'FechaTest'});
			this.$emit('submit', 'name', this.displayName)
      this.$emit('step-change', 3);
    }
  },

	created() {
		this.msg = messages
	},

  mounted() {
    this.$emit('step-change', 2)
		if (useAppStore().accountData.name?.length > 0) {
			this.displayName = useAppStore().accountData.name
		}
  },
};
</script>