import { createRouter, createWebHashHistory } from 'vue-router'
import { useAppStore } from '@/stores/index.js'
import userEventLogger from '@/eventLogger.js';

import AccountCreationView from '../views/accountcreation/AccountCreationView.vue'
import ACCuid from '@/components/accountcreation/ACCuid.vue';
import ACStart from '../components/accountcreation/ACStart.vue'
import ACName from '../components/accountcreation/ACName.vue'
import ACTestDate from '../components/accountcreation/ACTestDate.vue'
import ACEmail from '@/components/accountcreation/ACEmail.vue';
import ACPin from '@/components/accountcreation/ACPin.vue';

import OnboardingView from '../views/onboarding/OnboardingView.vue'
import LoginView from '@/views/LoginView.vue';
import RecoverPasswordView from '@/views/RecoverPasswordView.vue';
import HomeView from '@/views/HomeView.vue'

import QuestionsView from '../views/info/QuestionsView.vue'
import ArticlesView from '../views/info/ArticlesView.vue'
import ArticleView from '../views/info/ArticleView.vue'
import PodcastsView from '../views/info/PodcastsView.vue'
import StoriesView from '../views/info/StoriesView.vue'
import SubmitQuestionView from '../views/info/SubmitQuestionView.vue'
import SubmittedQuestionsView from '../views/info/SubmittedQuestionsView.vue'
import TestsView from '../views/info/TestsView.vue'

const routes = [
  {
    path: '/home',
    name: 'home',
    component: HomeView
  },
	{
    path: '/informacion',
    name: 'informacion',
    component: ArticlesView
  },
	{
		path: '/articulo/:id',
		name: 'ArticleView',
		component: ArticleView
	},
	{
    path: '/podcasts',
    name: 'PodcastsView',
    component: PodcastsView
  },
  {
    path: '/preguntas',
    name: 'QuestionsView',
    component: QuestionsView
  },
	{
    path: '/preguntas/enviadas',
    name: 'SubmittedQuestionsView',
    component: SubmittedQuestionsView
  },
	{
    path: '/preguntas/enviar',
    name: 'SubmitQuestionView',
    component: SubmitQuestionView
  },
	{
    path: '/testimonios',
    name: 'StoriesView',
    component: StoriesView
  },
	{
    path: '/testycontroles',
    name: 'TestsView',
    component: TestsView
  },
	{
		path: '/crearcuenta',
		name: 'crearcuenta',
		component: AccountCreationView,
		children: [
			{
				path: '',
				name: 'CUID',
				component: ACCuid
			},
			{
				path: 'onboarding',
				name: 'onboarding',
				component: OnboardingView
			},
			{
				path: 'inicio',
				name: 'Inicio',
				component: ACStart
			},
			{
				path: 'nombre',
				name: 'Nombre',
				component: ACName
			},
			{
				path: 'fecha',
				name: 'FechaTest',
				component: ACTestDate
			},
			{
				path: 'email',
				name: 'Email',
				component: ACEmail
			},
			{
				path: 'pin',
				name: 'PIN',
				component: ACPin,
			}
		]
	},
	{
		path: '/login',
		name: 'Login',
		component: LoginView
	},
	{
		path: '/recoverpassword',
		name: 'RecoverPassword',
		component: RecoverPasswordView
	}
]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
})

router.beforeEach((to, from) => {
	if (!useAppStore().accountData.token && !to.path.includes('/crearcuenta') && to.name !== 'CUID') {
		return { name: 'CUID' }
	}

	else if (useAppStore().accountData.token && !useAppStore().isLoggedIn && to.name !== 'Login') {
    return { name: 'Login' }
  }

	else {
		// if (to.name === 'articulo') {
		// 	userEventLogger.logPageVisit(to.fullPath)
		// }
	
		// if (from.name == 'video') {
		// 	userEventLogger.log('leave')
		// }
		
		return true
	}
 })

export default router
