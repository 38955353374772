<template>
	<div class="articles flex-v gap24">
		<div
			v-for="podcast in podcasts"
			class="article-card"
			:key="podcast.articleId"
		>
			<h3>{{ podcast.title }}</h3>
			<p v-html="podcast.content"></p>
			<router-link :to="{ name: 'ArticleView', params: { id: podcast.articleId } }">leer artículo</router-link>
		</div>
	</div>
</template>

<script>
export default {
	name: 'PodcastsView',
	data() {
		return {
			podcasts: []
		}
	},

	mounted() {
		fetch(process.env.VUE_APP_URL_API + 'api/article/list')
			.then(response => response.json())
			.then(data => this.podcasts = data.filter(art => art.articleTypeId == 'ad938440-0677-4220-b3fb-581e61a57548'))
			.catch(error => console.error('Error:', error));
	},
}
</script>

<style scoped>
.articles {
	padding: 3em;
}
</style>