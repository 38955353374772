<template>
  <router-view/>
	<BottomNav v-if="isLoggedIn"/>
</template>

<script>
import { useAppStore } from '@/stores/index.js'
import BottomNav from '@/components/BottomNav.vue'
export default {
	name: 'App',
	components: {BottomNav},
	data() {
		return {
			user: {},
		}
	},

	computed: {
		isLoggedIn() {
			return useAppStore().isLoggedIn
		}
	},

	created() {
	},

	mounted() {
		if ("virtualKeyboard" in navigator) {
			navigator.virtualKeyboard.overlaysContent = true
		}
	}
}
</script>

<style>
@import url(/tita-assets/dist/tita.css);
@import url(@/assets/css/titaapp.css);
</style>
