const messages = {
	'common': {
		'next': 'Siguiente',
		'omit': 'Omitir',
		'verify': 'Verificar'
	},
	'terms': '<p>Toda la información proporcionada en esta aplicación, incluyendo la contenida en textos, videos y audios, tiene únicamente fines informativos y está basada en la evidencia clínica más reciente disponible y el marco jurídico vigente en Argentina al momento de su publicación. En ningún caso sustituye el acto médico.</p><p>“TITA” es una herramienta de uso gratuito y no persigue fines de lucro.</p><p>Toda la información volcada en Tita es totalmente ANÓNIMA y CONFIDENCIAL.</p>',
	'walkthrough': {
		'wt1Title': '¡Te damos la bienvenida a Tita!',
		'wt1Content': 'Una app para acompañarte en el cuidado de tu salud.',
		'wt2Title': 'Informate sobre tu salud',
		'wt2Content': 'Encontrá respuestas a tus preguntas sobre el VPH y la prevención del cáncer de cuello de útero.',
		'wt3Title': 'Agendá tu paso a paso',
		'wt3Content': 'Registrá tus consultas y estudios para recibir notificaciones sobre tus próximos turnos.',
		'wt4Title': 'Explorá historias y testimonios reales',
		'wt4Content': 'Conocé la experiencia de quienes ya se hicieron el test de VPH.',
		'acceptTerms': 'Acepto los términos y condiciones'
	},
}

export default messages