<template>
	<div class="view articles flex-v gap24">
		<div
			v-for="article in articles"
			class="article-card"
			:key="article.articleId"
		>
			<h3>{{ article.title }}</h3>
			<p v-html="article.content"></p>
			<router-link :to="{ name: 'ArticleView', params: { id: article.articleId } }">leer artículo</router-link>
		</div>
	</div>
</template>

<script>
export default {
	name: 'ArticlesView',
	data() {
		return {
			articles: []
		}
	},

	mounted() {
		fetch(process.env.VUE_APP_URL_API + 'api/article/list', {
			method: 'GET',
			headers: new Headers({
				'Authorization': 'Basic '+btoa('tita_user:ef4515ee-5e13-423d-89f1-a2f4730ad346'), 
				"Content-Type": "application/json",
			}),
		})
		.then(response => response.json())
		.then(data => this.articles = data)
		.catch(error => console.error('Error:', error))
	},
}
</script>

<style scoped>
.articles {
	padding: 3em;
	overflow: auto;
}
</style>