<template>
	<input
		type="password"
		class="code-glyph"
		maxlength="1"
		:value="modelValue"
		inputmode="numeric"
    @keydown="onInput(index, $event)"
		@paste="onPaste(index, $event)"
	>
</template>

<script>
	export default {
		name: 'PinInput',
		props: ['isInvalid', 'modelValue', 'length'],
		data() {
			return {
				number: ''
			}
		},
		watch: {  
      number(newValue, oldValue) {
        if (newValue !== oldValue) {
          if (newValue === "") {
            this.$emit('prev')
          } else {
            this.$emit('next')
          }
        }
      },
    },
		emits: ['update:modelValue', 'prev', 'next', 'pasteText'],
		methods: {
			onInput() {
				event.preventDefault()
				if ((event.key.match(/[0-9]/) !== null || event.key == 'Backspace')) {
					if (event.key.match(/[0-9]/) !== null) {
						event.target.value = event.key
					}

					else if (event.key == 'Backspace') {
						event.target.value = ''
					}

					this.number = event.target.value
					this.$emit('update:modelValue', this.number)
				}
			},

			onPaste(index, event) {
        event.preventDefault()
        const clipboardData = event?.clipboardData || window?.clipboardData
      
        if (clipboardData) {
          const pastedText = clipboardData
						.getData("text").trim()
						.replace(/\D/g, "")
						.slice(0, 4)

          if (pastedText.length > 0) {
						this.$emit('pasteText', pastedText)
					}
        }
      },
		}
	}
</script>

<style scoped>
input.code-glyph {
	height: 56px;
	width: 56px;
	flex-grow: 0;
	padding: 8px 18px;
	text-align: center;
	font-size: var(--type-h1-size);
	font-weight: var(--type-h1-weight);
}

input.code-glyph[invalid] {
	border-color: var(--color-r100);
	background-color: unset;
	background-image: unset;
}
</style>