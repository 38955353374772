<template>
	<nav id="top-menu" class="top-menu">
		<button
			v-if="showNavBack"
			class="btn btn-icon"
			@click="navBack()"
		>
			<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M7.99992 12.6666L3.33325 7.99998L7.99992 3.33331" stroke="#474747" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
				<path d="M12.6666 8H3.33325" stroke="#474747" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
			</svg>
		</button>
		<ProgressBar
			:currentStep="currentStep"
			:totalSteps="totalSteps"
			v-if="showProgBar"
		/>
	</nav>
</template>

<script>
import ProgressBar from '@/components/accountcreation/ProgressBar.vue';

export default {
	name: 'TopMenu',
	props: ['currentStep', 'totalSteps', 'showProgBar', 'showNavBack'],
	components: {
		ProgressBar
	},

	data() {
		return {

		}
	},

	methods: {
		navBack() {
			this.$router.back()
		}
	}
}
</script>