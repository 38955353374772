<template>
	<div class="articles flex-v gap24">
		<div
			v-for="story in stories"
			class="article-card"
			:key="story.articleId"
		>
			<h3>{{ story.title }}</h3>
			<p v-html="story.content"></p>
			<router-link :to="{name: 'ArticleView', params: {id: story.articleId}}">ver testimonio</router-link>
		</div>
	</div>
</template>

<script>
export default {
	name: 'StoriesView',
	data() {
		return {
			stories: []
		}
	},

	mounted() {
		fetch(process.env.VUE_APP_URL_API + 'api/article/list', {
			method: 'GET',
			headers: new Headers({
				'Authorization': 'Basic '+btoa('tita_user:ef4515ee-5e13-423d-89f1-a2f4730ad346'), 
				"Content-Type": "application/json",
			}),
		})
		.then(response => response.json())
		.then(data => this.stories = data.filter(art => art.articleTypeCode == 'STO'))
		.catch(error => console.error('Error:', error))
	},
}
</script>

<style scoped>
.articles {
	padding: 3em;
}
</style>